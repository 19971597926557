import { useState, useCallback, useEffect } from 'react';

/**
 * Similar to `useState`, stores the value in local storage and returns the
 * stored value if present.
 *
 * The `initialValue` is always returned on the first render because Gatsby
 * requires the initial render to be deterministic.
 *
 * Optionally, provide a 'validate' function to verify or normalize the stored
 * value.
 */
export function usePersistentState<T>(
  key: string,
  initialValue: T,
  validate: (value: T) => T = (t) => t
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      const value = item ? JSON.parse(item) : null;

      if (value) {
        setStoredValue(validate(value));
      }
    } catch (error) {
      // no-op, this can fail in some browsers in incognito mode
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // no-op
      }
    },
    [key]
  );

  return [storedValue, setValue];
}
