import './StateStandardsAlignment.scss';

import relationshipsJSON from './state-standards-term-relationships.json';

import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Link } from 'gatsby';
import { compact, isEmpty } from 'lodash';

import { usePersistentState } from 'utils/usePersistentState';

import { replaceItemAtIndex, removeItemAtIndex } from 'utils/collections';

import * as Core from 'components/core';

import { StateStandard, StateStandardsTerm, Framework, Term } from 'types';
import classNames from 'classnames';

const relationships: Record<string, [number, number][]> =
  relationshipsJSON as any;

interface Props {
  stateStandards: StateStandard[];
  stateStandardsTerms: StateStandardsTerm[];
  frameworks: Framework[];
  terms: Term[];
  initialStateStandardsID: number | null;
}

const StateStandardsAlignment: React.FC<Props> = (props) => {
  const {
    stateStandards,
    stateStandardsTerms,
    frameworks,
    terms,
    initialStateStandardsID
  } = props;

  const [collapseEmpty, setCollapseEmpty] = useState(false);

  const [selectedStateStandardID, setSelectedStateStandardID] =
    usePersistentState<number | null>(
      'state-standards-alignment-selected-standard',
      null
    );

  const [relatednessThreshold, setRelatednessThreshold] = useState(0.25);

  const [selectedFrameworkIDs, setSelectedFrameworkIDs] = usePersistentState<
    (number | null)[]
  >('state-standards-alignment-selected-frameworks', [null]);

  const selectedFrameworks = useMemo(() => {
    return selectedFrameworkIDs.map((selectedFrameworkID) => {
      const framework = frameworks.find(
        (framework) => framework.identifier === selectedFrameworkID
      );

      const frameworkTerms = terms.filter(
        (term) => term.frameworkID === selectedFrameworkID
      );

      return { framework, terms: frameworkTerms };
    });
  }, [frameworks, selectedFrameworkIDs, terms]);

  useEffect(() => {
    if (initialStateStandardsID) {
      setSelectedStateStandardID(initialStateStandardsID);
      setSelectedFrameworkIDs([]);
    }
  }, [
    initialStateStandardsID,
    setSelectedFrameworkIDs,
    setSelectedStateStandardID
  ]);

  const selectedStateStandard = useMemo(() => {
    return selectedStateStandardID
      ? stateStandards.find(
          (stateStandard) =>
            stateStandard.identifier === selectedStateStandardID
        )
      : null;
  }, [selectedStateStandardID, stateStandards]);

  const selectedStateStandardTerms = useMemo(() => {
    if (!selectedStateStandardID) {
      return null;
    }
    const terms = stateStandardsTerms.filter(
      (term) => term.frameworkID === selectedStateStandardID
    );

    return terms.filter((term) => term.tier === 1);
  }, [selectedStateStandardID, stateStandardsTerms]);

  const selectFrameworkAtIndex = useCallback(
    (index, value) => {
      setSelectedFrameworkIDs(
        replaceItemAtIndex(selectedFrameworkIDs, index, value)
      );
    },
    [selectedFrameworkIDs, setSelectedFrameworkIDs]
  );

  const addFramework = useCallback(() => {
    setSelectedFrameworkIDs([...selectedFrameworkIDs, null]);
  }, [selectedFrameworkIDs, setSelectedFrameworkIDs]);

  const removeFramework = useCallback(
    (index: number) => {
      setSelectedFrameworkIDs(removeItemAtIndex(selectedFrameworkIDs, index));
    },
    [selectedFrameworkIDs, setSelectedFrameworkIDs]
  );

  const tableData = useMemo(() => {
    if (!selectedStateStandard || !selectedStateStandardTerms) {
      return null;
    }

    return compact(
      selectedStateStandardTerms.map((stateStandardTerm) => {
        const relatedTermsByFramework = selectedFrameworks.map((framework) => {
          return compact(
            relationships[stateStandardTerm.identifier.toString()]?.map(
              (relatedTerm) => {
                if (relatedTerm[1] < relatednessThreshold) {
                  return null;
                }
                const term = framework.terms.find(
                  (term) => term.identifier === relatedTerm[0]
                );

                if (term) {
                  return { ...term, relatedness: relatedTerm[1] };
                }
              }
            )
          );
        });

        if (
          collapseEmpty &&
          relatedTermsByFramework.every((terms) => terms.length === 0)
        ) {
          return null;
        }

        return {
          ...stateStandardTerm,
          relatedTermsByFramework
        };
      })
    );
  }, [
    collapseEmpty,
    relatednessThreshold,
    selectedFrameworks,
    selectedStateStandard,
    selectedStateStandardTerms
  ]);

  return (
    <div className="StateStandardsAlignment">
      <div className="StateStandardsAlignment-options">
        <label>
          Relatedness Threshold{' '}
          <input
            type="range"
            min={0.25}
            max={1}
            value={relatednessThreshold}
            step={0.05}
            style={{ width: '200px' }}
            onChange={(event) =>
              setRelatednessThreshold(parseFloat(event.currentTarget.value))
            }
          />
        </label>

        <Core.CheckboxField
          checked={collapseEmpty}
          onChange={(event) => setCollapseEmpty(event.currentTarget.checked)}
          label="Collapse Rows with No Aligning Terms"
        />

        <Core.SmallButton
          onClick={addFramework}
          title="Add Framework"
          disabled={selectedFrameworks.length >= 4}
        >
          + Add Framework
        </Core.SmallButton>
      </div>

      <div className="StateStandardsAlignment-frameworkSelection ">
        <div className="StateStandardsAlignment-cell">
          <div className="StateStandardsAlignment-optionHeading">
            <div>State Standard</div>
            {selectedStateStandardID && selectedStateStandard && (
              <Link
                to={`/state-standards/${selectedStateStandardID}`}
                className="StateStandardsAlignment-link"
                aria-label={`View ${selectedStateStandard?.name}`}
              >
                View
              </Link>
            )}
          </div>
          <div className="StateStandardsAlignment-select">
            <Core.Select
              value={selectedStateStandardID?.toString()}
              onChange={(event) =>
                setSelectedStateStandardID(parseInt(event.currentTarget.value))
              }
            >
              <option value="" />
              {stateStandards.map((stateStandard) => (
                <option
                  key={stateStandard.identifier}
                  value={stateStandard.identifier.toString()}
                >
                  {stateStandard.name}
                </option>
              ))}
            </Core.Select>
          </div>
        </div>

        {selectedFrameworks.map((selectedFramework, index) => (
          <div
            className="StateStandardsAlignment-cell"
            key={`${selectedFramework?.framework?.identifier}${index}`}
          >
            <div className="StateStandardsAlignment-optionHeading">
              <div>Framework</div>
              <div>
                {selectedFramework.framework && (
                  <Link
                    to={`/frameworks/${selectedFramework.framework.identifier}`}
                    className="StateStandardsAlignment-link"
                    aria-label={`View ${selectedFramework.framework.name}`}
                  >
                    View
                  </Link>
                )}{' '}
                &nbsp;
                {selectedFrameworks.length > 1 && (
                  <Core.TextButton onClick={() => removeFramework(index)}>
                    Remove
                  </Core.TextButton>
                )}
              </div>
            </div>

            <div className="StateStandardsAlignment-select">
              <Core.Select
                value={selectedFramework.framework?.identifier?.toString()}
                onChange={(event) =>
                  selectFrameworkAtIndex(
                    index,
                    parseInt(event.currentTarget.value)
                  )
                }
              >
                <option value="" />
                {frameworks.map((framework) => (
                  <option
                    key={framework.identifier}
                    value={framework.identifier.toString()}
                  >
                    {framework.name}
                  </option>
                ))}
              </Core.Select>
            </div>
          </div>
        ))}
      </div>

      {tableData && (
        <div aria-live="polite">
          {tableData.map((stateStandardTerm) => (
            <div
              key={stateStandardTerm.identifier}
              className="StateStandardsAlignment-row"
            >
              <div className="StateStandardsAlignment-cell">
                {stateStandardTerm.name}
              </div>

              {selectedFrameworks.map((selectedFramework, frameworkIndex) => (
                <div
                  className="StateStandardsAlignment-cell"
                  key={`${selectedFramework?.framework?.identifier}${frameworkIndex}`}
                >
                  {!isEmpty(
                    stateStandardTerm.relatedTermsByFramework[frameworkIndex]
                  ) ? (
                    stateStandardTerm.relatedTermsByFramework[
                      frameworkIndex
                    ].map((term) => (
                      <div
                        key={term.identifier}
                        className={classNames(
                          'StateStandardsAlignment-relatedTerm',
                          { 'is-stronglyRelated': term.relatedness >= 0.75 }
                        )}
                      >
                        <Link to={`/terms/${term.identifier}`}>
                          {term.name} - {(term.relatedness * 100).toFixed(0)}%
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div
                      aria-label={
                        selectedFramework?.framework != null
                          ? `No related terms in ${selectedFramework.framework.name}`
                          : ''
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {!selectedStateStandardID && (
        <div className="StateStandardsAlignment-empty">
          Select state standards to compare to frameworks
        </div>
      )}
    </div>
  );
};

export default StateStandardsAlignment;
