import './alignment.scss';

import React from 'react';
import { graphql } from 'gatsby';

import { config } from 'config';

import Layout from 'components/layout/Layout';
import { StateStandard, StateStandardsTerm, Framework, Term } from 'types';

import StateStandardsAlignment from 'components/state-standards-alignment/StateStandardsAlignment';

interface Props {
  location: {
    state: any;
  } | null;
  data: {
    allStateStandardsJson: {
      stateStandards: StateStandard[];
    };
    allStateStandardsTerms: {
      stateStandardsTerms: StateStandardsTerm[];
    };
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
  };
}

const Alignment: React.FC<Props> = (props) => {
  const { stateStandards } = props.data.allStateStandardsJson;
  const { stateStandardsTerms } = props.data.allStateStandardsTerms;
  const { frameworks } = props.data.allFrameworks;
  const { terms } = props.data.allTerms;

  const initialStateStandardsID = props?.location?.state?.stateStandardsID;

  if (!config.stateStandardsEnabled) {
    return null;
  }

  return (
    <Layout>
      <div className="">
        <h1>Compare State Standards Alignments</h1>
      </div>

      <div className="StateStandardsAlignment">
        <StateStandardsAlignment
          stateStandards={stateStandards}
          stateStandardsTerms={stateStandardsTerms}
          frameworks={frameworks}
          terms={terms}
          initialStateStandardsID={initialStateStandardsID}
        />
      </div>
    </Layout>
  );
};

export default Alignment;

export const query = graphql`
  query StateStandardsAlignmentQuery {
    allStateStandardsJson {
      stateStandards: nodes {
        ageRange
        citation
        name
        name
        identifier
        link
        organization
        state
        stateAbbreviation
      }
    }
    allStateStandardsTerms: allStateStandardsTermsJson {
      stateStandardsTerms: nodes {
        identifier
        name
        parentID
        frameworkID
        tier
      }
    }
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        group
        name
        age
        setting
        learningProgression
        color
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkID
        frameworkName
        parentID
        tier
        definition
      }
    }
  }
`;
